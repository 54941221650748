<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">流程六要素数字化</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        流程由6个要素组成，就是人机物法，加上输入、输出和准则这6个要素，见海龟图：
      </p>
      <img src="@/assets/FirstPage-ProductSon/0.png" class="SonImg" />
      <p class="SmallP">
        6个要素中，因材料和设备是固定的，况且很多流程并不需要材料和设备，故除了这个要素外，就要对以下的五个要素数字化。
      </p>
      <img src="@/assets/FirstPage-ProductSon/01.png" class="SonImg" />
      <p class="SmallP">
        具体做法就是将这五个要素进行细分，细分的颗粒度越细就越好，对输出的分类如：方案、计划、合同和协议、要求、报告和报表、档案、清单、表单、记录、单据等等；合同还可以再分材料合同、供货合同、外协合同等。然后对每个分类给与唯一的数字编码，用编码进行管理。这就是流程要素数字化。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>